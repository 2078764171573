<template>
  <Form :items="items" />
</template>


<script>
import useI18n from '@/plugins/useI18n';
import Form from '../Form/Step1.vue'
export default {
  props: {
      items: {
          type: Object,
      }
  },
  components: {
      Form
  },
  setup() {
    const { i18n } = useI18n();
    return {
      i18n,
    };
  }
};
</script>




